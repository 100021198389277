.projects {
  padding: 0 0 4rem 0;
  /* background-color: red; */
  width: 100%;
  position: relative;
  display: grid;
  flex-direction: column;
  gap: 4rem;
}

.project_content_container {
  display: grid;
  /* grid-template-rows: 4rem 3fr 2fr; */
  grid-template-columns: repeat(12, 1fr);
  opacity: 0;
  animation: init 1s ease forwards;
  animation-delay: 0.4s;
  padding: 4rem 0 0 0;
}

.section_num {
  grid-column: 1/2;
}

.section_name {
  grid-column: 6/7;
}

.section_date {
  grid-column: 10/-1;
}
/*  */
.projects_subheading {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  align-items: end;
}
.projects_note,
.projects_h2_large {
  text-transform: uppercase;
}
.projects_note {
  grid-column: 2/5;
  font-size: 2rem;
  max-width: 30rem;
}
.projects_h2_large {
  grid-column: 6/8;
  font-size: 6vw;
  line-height: 0.9;
}

.projects_ul {
  /* background-color: red; */
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  position: relative;
  margin: 12rem 0;
}
.proj_li_1 {
  grid-column: 1/6;
  /* background-color: lime; */
}
.mobile_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.mobile_image {
  max-width: 50rem;
  width: 100%;
}

.proj_li_1 {
  grid-row: 1/1;
}

.project_image1 {
  position: absolute;
  left: 60%;
  top: -15rem;
  height: auto;
  width: 20vw;
  opacity: 0;
  transition: opacity 1.2s ease;
  /* transition-delay: 200ms; */
  pointer-events: none;
}

.proj_li_1:hover ~ .project_image1 {
  opacity: 1;
  transform: translate(-20px, -200px) rotate(-5deg);
}

@keyframes init {
  from {
    opacity: 0;
    transform: translate(0, 5rem);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media (max-width: 66em) {
  .projects_note {
    grid-column: 1/5;
  }
  .project_image1 {
    top: -5rem;
  }
}
@media (max-width: 61em) {
  .projects_subheading {
    row-gap: 4rem;
  }
  .projects_note {
    grid-row: 2/2;
    grid-column: 1/-1;
  }
  .projects_h2_large {
    grid-column: 1/6;
    grid-row: 1/1;
  }
  .project_image1,
  .project_image2,
  .project_image3 {
    display: none;
  }
  .mobile_img_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile_image {
    width: 100%;
  }
  .proj_li_1,
  .proj_li_2,
  .proj_li_3,
  .proj_li_4 {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: 100%;
  }
  .projects_ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2rem;
    width: 100%;
    margin: 0;
  }
  .projects {
    padding: 0;
  }
}
