.divider {
  height: 1px;
  background-color: grey;
  width: 0%;
  position: absolute;
  top: 0;
  left: 0;
  animation: load 1.2s forwards ease-out;
}

@keyframes load {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
