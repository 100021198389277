.project_wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 3rem 0;
  gap: 4rem;
  width: 100%;
  cursor: pointer;
}

.project_details {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.project_name {
  font-size: 1.8rem;
  text-transform: uppercase;
  line-height: 1;
}
.project_date {
  font-size: 1.2rem;
  text-transform: uppercase;
}

/* imgs */

/* .img_container {
  position: absolute;
  background-color: red;
  height: 500px;
  width: 500px;
  visibility: hidden;
  opacity: 0;
  transition: all 1.2s ease;
  left: 140%;
  top: -30%;
}

.project_wrapper:hover .img_container {
  visibility: visible;
  opacity: 1;
} */

/* animations and stuff */
.divider {
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: grey;
  bottom: 0;
}

.load_bar {
  position: absolute;
  height: 1px;
  width: 0%;
  background-color: #fff;
  bottom: 0;
  transition: all 1.2s ease-out;
}

.arrow {
  position: absolute;
  /* height: 3rem;
  width: 3rem;
  background-color: lime; */
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: all 1.2s ease;
}

.arrow ion-icon {
  height: 3rem;
  width: 3rem;
}
.project_wrapper:hover .load_bar {
  width: 100%;
}

.project_date,
.project_details {
  transition: all 1.2s ease;
}
.project_wrapper:hover .project_date {
  transform: translate(20px);
}
.project_wrapper:hover .project_details {
  transform: translate(20px);
}

.project_wrapper:hover .arrow {
  transform: rotate(-30deg) translate(-10px, -20px);
}
/* animation: load 1.2s forwards ease-out; */

/* @keyframes load {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
} */
@media (max-width: 61em) {
  .load_bar {
    display: none;
  }
  .project_wrapper:hover .project_date {
    transform: translate(0px);
  }
  .project_wrapper:hover .project_details {
    transform: translate(0px);
  }

  .project_wrapper:hover .arrow {
    transform: none;
  }
  .arrow ion-icon {
    display: none;
  }
}
