/* heading */
/* should use the section heading component but i need to re create that entire component */
.heading {
  width: 100%;
  height: 2rem;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 4rem 0;
}

.grey_label {
  grid-column: 9/10;
  color: grey;
  font-size: 1.2rem;
}
/* about */
.about {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto auto auto;
  row-gap: 12rem;
  column-gap: 1rem;
  width: 100%;
}
.label_container {
  grid-column: 2/3;
  grid-row: 1/2;
  align-items: flex-end;
}
.about_avatar {
  height: 10rem;
  width: 10rem;
  background-image: url(../../assets/selfie.jpg);
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  align-self: flex-end;
  grid-column: 3/4;
}

.heading_wrapper {
  grid-column: 5/-1;
}

.about_h2 {
  font-size: 5vw;
  text-transform: uppercase;
  line-height: 0.9;
}
.about_subheading {
  grid-row: 2/3;
  grid-column: 1/4;
  text-transform: uppercase;
  font-size: 1.6rem;
  width: 80%;
  line-height: 1.5;
}

.about_text_block_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: 5 /11;
  grid-row: 2/2;
  gap: 4rem;
}
.about_text_block {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.about_text_section {
  grid-column: 2/-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}
.about_h3 {
  font-size: 2.4rem;
  text-transform: uppercase;
}

.about_text {
  /* text-transform: uppercase; */
  font-size: 1.6rem;
  color: grey;
}
/* services */

.services_container {
  grid-column: 6 /-2;
  grid-row: 3/3;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  /* min-height: 30rem; */
  gap: 2rem;
}
.services_content_container {
  /* display: flex;
  justify-content: start;
  align-items: start; */
  display: grid;
  grid-template-columns: 1fr 9fr;
  position: relative;
  padding-bottom: 2.4rem;
}

.service_title {
  font-size: 2.2rem;
  text-transform: capitalize;
  line-height: 0.8;
}

.services_description {
  color: grey;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding-top: 0.4rem;
}

.bottom_divider {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 1px;
  background-color: grey;
}

/* numbers */

.numbers_container {
  grid-column: 6 /-2;
  grid-row: 4/4;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 6rem;
  /* align-items: stretch;
  justify-items: stretch; */
}
.about_h3__services {
  grid-row: 3/3;
  grid-column: 2/2;
  font-size: 2.4rem;
  text-transform: uppercase;
}
.about_h3__numbers {
  grid-column: 2/2;
  grid-row: 4/4;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.number_block {
  position: relative;
}

.top_divider {
  position: absolute;
  width: 100%;
  top: 0;
  height: 1px;
  background-color: grey;
}

.number {
  font-size: 6vw;
  font-weight: 600;
}

.number_description {
  color: grey;
  text-transform: uppercase;
  font-size: 1.2rem;
}
/* ////////////////////////////
///////////////////////////////
////////////////////////////// */
@media (max-width: 61em) {
  .about {
    grid-template-rows: repeat(10, auto);
    row-gap: 2rem;
  }
  .label_container {
    grid-column: 1/1;
    grid-row: 1/2;
    align-items: flex-end;
  }
  .about_h2 {
    font-size: 4.8rem;
    line-height: 0.85;
  }
  .about_avatar {
    grid-row: 2/2;
    grid-column: 1/-1;
    /* height: 5rem;
    width: 5rem; */
  }
  .heading_wrapper {
    grid-row: 3/3;
    grid-column: 1/-1;
  }
  .about_subheading {
    grid-row: 4/4;
    grid-column: 1/-1;
    margin-bottom: 6rem;
  }
  .about_text_block_container {
    grid-row: 5/5;
    grid-column: 1/-1;
    gap: 3rem;
    margin-bottom: 6rem;
  }
  .about_h3__numbers {
    grid-row: 9/9;
  }
  .about_h3__services {
    grid-column: 1/-1;
    grid-row: 6/6;
  }
  .services_container {
    grid-column: 1/-1;
    grid-row: 7/7;
    /* gap: 2rem; */
    padding: 1rem 0 0 0;
    margin-bottom: 6rem;
  }

  .about_h3__numbers {
    grid-column: 1/-1;
  }
  .numbers_container {
    grid-row: 10/-1;
    grid-column: 1/-1;
    margin-bottom: 6rem;
  }
  .number_block {
    padding: 1rem 0 0 0;
  }
  .grey_label {
    grid-column: 10/-1;
  }
  .number {
    font-size: 6.4rem;
  }
}

@media (max-width: 42em) {
  .about_text_block {
    /* grid-template-columns: 3rem auto; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .about_h2 {
    font-size: 2.4rem;
    line-height: 1;
  }
  .number {
    font-size: 3.6rem;
  }
  .about_avatar {
    height: 5rem;
    width: 5rem;
  }
}

/*  height: 10rem;
  width: 10rem; */
