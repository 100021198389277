/* navbar */
.navbar {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding-top: 2rem;
  row-gap: 2rem;
}

.back_btn {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  text-transform: uppercase;
  border: none;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
}
.back_btn ion-icon {
  /* background-color: white; */
  height: 2rem;
  width: 2rem;
}
.navbar_date {
  grid-column: 6/6;
}

.navbar_brand {
  grid-column: 9/9;
}
/* general */
.project {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  padding: 6rem 0;
}
.heading {
  position: relative;
  font-size: 6vw;
  text-transform: uppercase;
  padding: 1rem 0;
  font-weight: 500;
}

.grid_12_col {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  row-gap: 6rem;
}

.detail_label {
  grid-column: 4/4;
  font-size: 1.2rem;
  grid-row: 1/1;
  text-transform: uppercase;
}

.description {
  font-size: 1.8rem;
  grid-column: 6/10;
  grid-row: 1/1;
}

.link_container {
  grid-row: 2/2;
  grid-column: 2/4;
  display: flex;
  background-color: white;
  color: black;
  border-radius: 32px;
  height: 4rem;
  width: 16rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.site_link {
  font-size: 1.4rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.btn_disabled {
  pointer-events: none;
}

.project_info_container {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row: 2/2;
  grid-column: 6/11;
}
.project_info_block {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
}

.bottom_divider {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 1px;
  background-color: grey;
}

.info_title {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 400;
}
.info_description {
  color: grey;
  text-transform: uppercase;
  font-size: 1.2rem;
}

@media (max-width: 61em) {
  .navbar {
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(3, 1fr); */
  }
  .navbar_date {
    grid-column: 1/1;
    grid-row: 2/2;
  }
  .navbar_brand {
    grid-column: 2/2;
    grid-row: 2/2;
  }

  .grid_12_col {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
  }
  .detail_label {
    grid-column: 1/1;
    grid-row: 1/1;
  }
  .description {
    grid-column: 2/-1;
    grid-row: 1/1;
  }
  .project_info_container {
    grid-row: 3/3;
    grid-column: 1/-1;
  }
}

@media (max-width: 48em) {
  .grid-12-col {
    grid-template-rows: repeat(4, auto);
  }
  .description {
    grid-column: 1/-1;
    grid-row: 2/2;
  }
  .link_container {
    grid-row: 3/3;
    grid-column: 1/1;
  }
  .project_info_container {
    grid-row: 4/4;
    grid-column: 1/-1;
  }
}
