/* ===== General Footer Styling ===== */
.footer {
  width: 100%;
  padding-bottom: 8rem;
}

/* ===== Heading Section ===== */
.heading {
  width: 100%;
  height: 2rem;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr 1fr;
  padding: 4rem 0;
}

.top_divider {
  position: absolute;
  width: 100%;
  top: 0;
  height: 1px;
  background-color: grey;
}

.grey_label {
  grid-column: 9/10;
  color: grey;
  font-size: 1.2rem;
  text-transform: uppercase;
}

/* ===== Image Section ===== */
.img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
}

.contact_image {
  width: 100%;
  height: auto;
}

/* ===== Form Section ===== */

/* Container for Form and Success Message */
.form_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px; /* Fixed height for consistent layout */
}

.form_wrapper,
.success_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Form Styling */
.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  max-width: 600px; /* Wider for desktop screens */
  width: 100%;
}

/* Input and Textarea Styling */
.contact_input,
.contact_textarea {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 8px;
  outline: none;
  color: #fff;
  padding: 0.8rem 1rem;
  font-size: 1.6rem; /* Larger font for desktop */
  transition: all 0.3s ease;
}

.contact_input::placeholder,
.contact_textarea::placeholder {
  color: #ccc;
  font-size: 1.4rem;
}

.contact_input:focus,
.contact_textarea:focus {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.5); /* Subtle glow effect */
}

.contact_textarea {
  resize: none;
  height: 7rem;
  font-family: inherit;
}

/* Button Styling */
.contact_btn {
  border: none;
  background-color: #333;
  color: white;
  border-radius: 32px;
  height: 4rem;
  width: 16rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact_btn:hover {
  background-color: #444; /* Slightly lighter on hover */
  transform: scale(1.05);
}

/* ===== Feedback Messages ===== */

/* Success Message Styling */
.success_message {
  padding: 1.6rem;
  border-radius: 8px;
  text-align: center;
  font-size: 1.6rem;
  background-color: rgba(76, 175, 80, 0.1);
  color: #4caf50;
  border: 1px solid #4caf50;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Error Message Styling */
.error_message {
  margin-top: 15px;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  background-color: rgba(244, 67, 54, 0.1);
  color: #f44336;
  border: 1px solid #f44336;
  max-width: 400px;
  width: 100%;
}

/* Animations */
.fadeIn {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 1;
}

.fadeOut {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 0;
}

/* ===== Contact Details Section ===== */
.contact_details {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  padding: 4rem 0;
}

.footer_direct_links,
.footer_social_links {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  grid-row: 2/2;
  padding: 2rem 0 0;
}

.footer_direct_links {
  grid-column: 6/6;
}

.footer_social_links {
  grid-column: 9/9;
}

.footer_link {
  font-size: 1.2rem;
  text-transform: uppercase;
}

/* ===== Keyframes ===== */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ===== Responsive Design ===== */
@media (max-width: 42em) {
  .contact_input,
  .contact_textarea {
    width: 80vw;
  }
  .form_container {
    height: 300px;
  }
}
