.sec_contain {
  padding-top: 20rem;
  width: 100%;
}
.hero {
  position: relative;
  width: 100%;
}

.hero_content_container {
  display: grid;
  grid-template-rows: 3fr 2fr;
  grid-template-columns: repeat(12, 1fr);
  opacity: 0;
  animation: init 1s ease forwards;
  animation-delay: 0.4s;
  padding: 4rem 0;
  row-gap: 4rem;
}
.hero_availability,
.hero_greeting,
.hero_works {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.hero_greeting {
  grid-row: 1/1;
  grid-column: 1/1;
}
.hero_works {
  grid-row: 1/1;
  grid-column: 6 / 8;
}
.hero_availability {
  grid-row: 1/1;
  grid-column: 10/-1;
}

.hero_h1 {
  grid-row: 3/-1;
  grid-column: 6/-2;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 3.2rem;
}
.hero_num_label {
  grid-row: 2/2;
  grid-column: 4/5;
}

.name_container_desktop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
}
.name {
  /* padding: 6rem 10rem 0; */
  width: 80%;
  height: auto;
  opacity: 0;
  animation: init 1s ease forwards;
  animation-delay: 0.4s;
}
/*   opacity: 0;
  animation: init 1s ease forwards;
  animation-delay: 0.4s; */
@keyframes init {
  from {
    opacity: 0;
    transform: translate(0, 5rem);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media (max-width: 72em) {
  .hero_num_label {
    grid-column: 1/1;
    grid-row: 3/3;
  }
  .hero_h1,
  .hero_works {
    grid-column: 5/-1;
  }
  .hero_availability {
    grid-column: 9/-1;
  }
  .hero_content_container {
    row-gap: 1rem;
  }
}

@media (max-width: 39em) {
  .hero_content_container {
    grid-template-rows: 3fr 2fr 2fr;
  }
  .hero_num_label {
    grid-row: 2/2;
  }
  .hero_works {
    grid-column: 3/7;
  }
  .hero_availability {
    grid-column: 8/-1;
  }
  .hero_h1 {
    grid-row: 3/3;
    grid-column: 1/-1;
  }
  .name_container_desktop {
    display: none;
  }
}

@media (max-width: 30em) {
  .hero_content_container {
    grid-template-rows: repeat(5, auto);
    row-gap: 4rem;
  }
  .hero_works {
    grid-row: 2/2;
  }
  .hero_num_label {
    grid-row: 4/4;
  }
  .hero_works {
    grid-column: 1/-1;
  }
  .hero_availability {
    grid-column: 1/-1;
    grid-row: 3/3;
  }
  .hero_h1 {
    grid-row: 5/5;
  }
}
