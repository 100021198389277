* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  color: inherit;
  text-decoration: none;
}
ul,
li,
ol {
  list-style: none;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5rem;
  gap: 8rem;
}
.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 3rem 3rem 0;
}

/* general */
.div-absolute {
  position: absolute;
  top: 0;
}
.nav-link {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.label {
  text-transform: uppercase;
  font-size: 1.2rem;
}

.parallax_img_global {
  transition: transform 1s ease-out;
}
@media (max-width: 42em) {
  .App {
    padding: 0 3rem;
  }
}

/* break points - 61 em */
