.header {
  position: absolute;
  top: 3rem;
  /* background-color: #1d1d1f; */
  width: calc(100% - 10rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand {
  height: 8em;
  width: 8rem;
  /* background-color: red; */
  display: flex;
  align-items: center;
}
.brand_link {
  font-size: 1.8rem;
  text-transform: uppercase;
  opacity: 0;
  animation: init 1s ease forwards;
  animation-delay: 0.4s;
}

.nav_ul {
  display: flex;
  align-items: center;
  gap: 2rem;
  opacity: 0;
  animation: init 1s ease forwards;
  animation-delay: 0.4s;
}

@media (max-width: 30em) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
}

@keyframes init {
  from {
    opacity: 0;
    transform: translate(0, 5rem);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media (max-width: 42em) {
  .header {
    width: calc(100% - 6rem);
  }
}
